<template>
  <div>
    <vs-icon
      size="medium"
      :icon="params.value ? 'check' : 'close'"
      :class="['h-5 w-5 mr-4', params.value ? 'text-success' : 'text-danger']"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererCheck',
};
</script>
