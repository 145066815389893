<template>
  <div>
    <!-- <feather-icon
      icon="SettingsIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="configRecord"
    >
    </feather-icon> -->
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
    <feather-icon
      icon="TrashIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
      @click="confirmDeleteRecord"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord() {
      const delegation = this.params.data;
      this.$gates.hasPermission('edit_delegations') &&
        this.params.context.componentParent.promptDelegation(delegation);

      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose
              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
    configRecord() {
      // this.params.context.componentParent.changeStatus({
      //   status: this.params.data.status,
      //   negociacion: this.params.data,
      //   observacion: `Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}, Value: ${this.params.data.status}`,
      // });
    },
    confirmDeleteRecord() {
      this.$gates.hasPermission('edit_delegations') &&
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirmar eliminación`,
          text: `¿Estás seguro de borrar la delegación ${this.params.data.id}"?`,
          accept: this.deleteRecord,
          acceptText: 'Eliminar',
          cancelText: 'Cancelar',
        });
    },
    deleteRecord() {
      const delegation = this.params.data;
      this.params.context.componentParent.deleteDelegation(delegation);
    },
  },
};
</script>
