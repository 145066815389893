<template>
  <vx-card class="tabla-delegacion">
    <ValidationObserver ref="validateFormDelegation" v-slot="{ invalid }">
      <vs-prompt
        class="tabla-delegacion__prompt"
        :active.sync="activePromptDelegar"
        :title="titlepromp"
        :is-valid="!invalid"
        @accept="delegarSocio"
        accept-text="Guardar"
        cancel-text="Descartar"
      >
        <div class="vx-col w-full mb-3">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="Socio logístico"
          >
            <vs-select
              label="Socio"
              v-model="deliveryman"
              :disabled="editing"
              class="w-full"
              placeholder="Socio logístico"
              autocomplete
            >
              <div
                class="items-select"
                v-for="(item, index) in deliverymen.filter(
                  (d) => d.data_status === 'verified'
                )"
                :key="index"
                :v-if="item.data_status == 'verified'"
              >
                <vs-select-item
                  :value="item.id"
                  :text="`${item.code ? item.code : 'N/A'} - ${
                    item.user.first_name + ' ' + item.user.last_name
                  }`"
                />
              </div>
            </vs-select>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="planta"
          >
            <vs-select
              class="w-full"
              label="Plantas"
              v-model="plant"
              placeholder="Seleccione una planta"
            >
              <vs-select-item
                :key="index"
                :text="item.name"
                :value="item.id"
                v-for="(item, index) in plants"
              />
            </vs-select>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="ambiente de delegación"
          >
            <vs-select
              class="w-full"
              label="Ambiente delegación"
              v-model="enviromentDelegationsCreate"
              placeholder="Seleccione ambiente de delegación"
            >
              <vs-select-item value="PLANNED" text="Planeada " />

              <vs-select-item value="PRODUCTION" text="Producción " />
            </vs-select>
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <vs-table class="mt-3" stripe :data="delegacionSeleccionada">
          <template slot="thead">
            <vs-th> LUN </vs-th>
            <vs-th> MAR </vs-th>
            <vs-th> MIE </vs-th>
            <vs-th> JUE </vs-th>
            <vs-th> VIE </vs-th>
            <vs-th> SAB </vs-th>
            <vs-th> DOM </vs-th>
          </template>

          <template slot-scope="{}">
            <vs-tr>
              <vs-td
                v-for="(day, index) in Object.keys(delegationDays)"
                :key="index"
              >
                <vs-icon
                  v-if="delegationDays[day]"
                  size="small"
                  icon="check"
                  @click="delegationDays[day] = !delegationDays[day]"
                  class="h-5 w-5 mr-4 text-success cursor-pointer"
                />
                <vs-icon
                  v-else
                  @click="delegationDays[day] = !delegationDays[day]"
                  size="small"
                  icon="close"
                  class="h-5 w-5 mr-4 text-danger cursor-pointer"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-prompt>
    </ValidationObserver>

    <!-- TABLE ACTION ROW -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-select
          class="w-full"
          label="Plantas"
          v-model="filterPlantId"
          placeholder="Seleccione una planta"
        >
          <vs-select-item value="" text="Todas" />

          <vs-select-item
            :key="index"
            :text="item.name"
            :value="item.id"
            v-for="(item, index) in plants"
          />
        </vs-select>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-select
          class="w-full"
          label="Ambiente delegación"
          v-model="enviromentDelegations"
          placeholder="Seleccione un ambiente de delegación"
        >
          <vs-select-item value="PLANNED" text="Planeada " />

          <vs-select-item value="PRODUCTION" text="Producción " />
        </vs-select>
      </div>
    </div>
    <!-- <div class="flex justify-between items-center">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
      <div>
        <vs-button class="mb-4 md:mb-0" @click="activeprompAdd"
          >Añadir</vs-button
        >
        <vs-button
          @click="exportDelegations"
          type="border"
          class="hidden md:block mb-3"
          >Exportar
        </vs-button>
      </div>
    </div> -->
    <div class="flex flex-wrap sm:flex-no-wrap sm:mt-5">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
      <div class="flex">
        <vs-button
          class="mb-4 md:mb-0 mr-2"
          :disabled="!$gates.hasPermission('add_delegations')"
          @click="activeprompAdd"
          >Añadir</vs-button
        >
        <vs-button
          @click="exportAllDelegations"
          type="border"
          class="hidden md:block mb-0"
          >Exportar
        </vs-button>
      </div>
      <!-- ACTION - DROPDOWN -->
      <vs-dropdown vs-trigger-click class="cursor-pointer md:hidden">
        <div
          class="p-3 mb-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32"
        >
          <span class="mr-2 leading-none">Acciones</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>

        <vs-dropdown-menu>
          <vs-dropdown-item>
            <span class="flex items-center">
              <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
              <span>Exportar CSV</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="delegations"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererActions from './cell-renderer/CellRendererActions';
import CellRendererCheck from './cell-renderer/CellRendererCheck';
import { defineComponent, watch, ref, computed } from '@vue/composition-api';
import {
  UPDATE_PLANT_DELEGATION,
  DELETE_PLANT_DELEGATION,
  CREATE_PLANT_DELEGATION,
} from '@/graphql/mutations';
import {
  PLANTS,
  FRANCHISES,
  DELEGATIONS,
  DELIVERYMEN,
  NO_ASGINED_TO_PLANT_DELIVERYMEN,
} from '@/graphql/queries';
import { useQuery, useResult } from '@vue/apollo-composable';
export default defineComponent({
  components: {
    AgGridVue,
    CellRendererActions,
    CellRendererCheck,
  },
  setup(_, { root }) {
    const { result: deliverymenResult } = useQuery(DELIVERYMEN);
    const filterPlantId = ref('');
    const currentPage = ref(1);
    const enviromentDelegations = ref('PLANNED');
    const fileName = 'delegations.xlsx';
    const first = ref(15);
    const searchQuery = ref('');

    const {
      result: delegationsResult,

      variables: delegationsVariables,
    } = useQuery(
      DELEGATIONS,
      {
        page: currentPage,
        first: first,
        search: searchQuery,
        environment: enviromentDelegations,
      },
      () => ({
        fetchPolicy: 'no-cache',
      })
    );
    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );
    const delegations = useResult(
      delegationsResult,
      [],
      (data) => data.delegations.data
    );

    watch(enviromentDelegations, (environment) => {
      currentPage.value = 1;
      const payload = {
        first: first,
        page: currentPage,
        environment,
        search: searchQuery,
      };
      if (filterPlantId.value) {
        payload.plant_id = filterPlantId;
      }
      delegationsVariables.value = payload;
      // refetchDelegations();
    });

    const paginatorInfo = useResult(delegationsResult, [], (data) => ({
      total: data.delegations.paginatorInfo.total,
      perPage: data.delegations.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    watch(filterPlantId, (plantId) => {
      currentPage.value = 1;
      const payload = {
        first: first,
        page: currentPage,
        environment: enviromentDelegations,
        search: searchQuery,
      };
      if (plantId) {
        payload.plant_id = plantId;
      }

      delegationsVariables.value = payload;
      // refetchDelegations();
    });

    const exportAllDelegations = () => {
      console.log('Export delegations');

      root.$http
        .get('api/delegations/export', { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      delegations,
      filterPlantId,
      deliverymen,
      currentPage,
      enviromentDelegations,
      totalPages,
      exportAllDelegations,
      searchQuery,
    };
  },
  data() {
    return {
      //VSPromptStatus
      activePromptDelegar: false,

      editing: false,
      titlepromp: '',
      enviromentDelegationsCreate: '',

      // Table

      delegacionSeleccionada: [{}],
      deliveryman: null,
      plant: null,
      delegation: null,
      delegationDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },

      //Grid
      // searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: '#',
          field: 'id',
          width: 80,
          filter: true,
        },
        {
          headerName: 'Código',
          field: 'deliveryman.code',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Nombre',
          field: 'deliveryman.user.first_name',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Apellido',
          field: 'deliveryman.user.last_name',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Teléfono',
          field: 'deliveryman.user.phone',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Franquicia',
          field: 'plant.franchise.name',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Planta',
          field: 'plant.name',
          filter: true,
          width: 200,
        },

        {
          headerName: 'Lunes',
          field: 'monday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Martes',
          field: 'tuesday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Miércoles',
          field: 'wednesday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Jueves',
          field: 'thursday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Viernes',
          field: 'friday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Sábado',
          field: 'saturday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },
        {
          headerName: 'Domingo',
          field: 'sunday',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererCheck',
        },

        {
          headerName: 'Acciones',
          field: 'acciones',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      delegaciones: [],
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
        // this.gridOptions.columnApi.setColumnPinned('email', null);
      }
    },
  },
  apollo: {
    franchises: {
      query: FRANCHISES,
    },
    plants: {
      query: PLANTS,
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
  },

  methods: {
    activeprompAdd() {
      if (this.$gates.hasPermission('add_delegations')) {
        this.activePromptDelegar = true;
        this.editing = false;
      }
    },
    async deleteDelegation(delegation) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_PLANT_DELEGATION,
          variables: {
            id: delegation.id,
          },
          update: (cache, { data: { deletePlantDelegation } }) => {
            const data = cache.readQuery({
              query: DELEGATIONS,
              variables: {
                page: 1,
                first: 15,
                environment: this.$data.enviromentDelegations,
              },
            });

            const removeIndex = data.delegations.data
              .map((delegation) => delegation.id)
              .indexOf(deletePlantDelegation.id);

            ~removeIndex && data.delegations.data.splice(removeIndex, 1);

            cache.writeQuery({
              query: DELEGATIONS,
              data,
              variables: {
                page: 1,
                first: 15,
                environment: this.$data.enviromentDelegations,
              },
            });
          },
        });
        this.$vs.notify({
          color: 'success',
          title: 'Delegación eliminada',
          text: 'La delegación fue correctamente eliminada',
        });
      } catch {
        this.$vs.notify({
          color: 'danger',
          title: 'Oops!',
          text: 'Ocurrió un problema al eliminar la delegación.',
        });
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    promptDelegation(delegation) {
      this.titlepromp = 'Delegar socio a planta';
      this.editing = true;
      this.activePromptDelegar = true;
      this.delegation = delegation;
      this.delegation.id = delegation.id;
      this.deliveryman = delegation.deliveryman.id;
      this.plant = delegation.plant.id;
      this.enviromentDelegationsCreate = delegation.environment;
      this.delegationDays.monday = delegation.monday;
      this.delegationDays.tuesday = delegation.tuesday;
      this.delegationDays.wednesday = delegation.wednesday;
      this.delegationDays.thursday = delegation.thursday;
      this.delegationDays.friday = delegation.friday;
      this.delegationDays.saturday = delegation.saturday;
      this.delegationDays.sunday = delegation.sunday;
    },
    async delegarSocio() {
      if (this.editing) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_PLANT_DELEGATION,
            variables: {
              input: {
                id: this.delegation.id,
                plant_id: this.plant,
                deliveryman_id: this.deliveryman,
                ...this.delegationDays,
                environment: this.enviromentDelegationsCreate,
              },
            },
            update: (cache, { data: { updatePlantDelegation } }) => {
              this.updateDelegation(cache, { data: { updatePlantDelegation } });
            },
          })
          .then(() => {
            this.$vs.notify({
              color: 'success',
              title: 'Delegación actualizada.',
              text: 'La delegación fue correctamente actualizada.',
            });
            this.gridApi.refreshCells();
          })
          .catch(() => {
            this.$vs.notify({
              color: 'danger',
              title: 'Delegación  no actualizada.',
              text: 'La delegación no fue correctamente actualizada.',
            });
          });
      } else {
        await this.$apollo
          .mutate({
            mutation: CREATE_PLANT_DELEGATION,
            variables: {
              input: {
                plant_id: this.plant,
                deliveryman_id: this.deliveryman,
                environment: this.enviromentDelegationsCreate,
                ...this.delegationDays,
              },
            },
            update: (cache, { data: { createPlantDelegation } }) => {
              this.createDelegation(cache, { data: { createPlantDelegation } });
            },
          })
          .then(() => {
            this.$vs.notify({
              color: 'success',
              title: 'Delegación creada!',
              text: 'La delegación fue correctamente creada.',
            });
            this.gridApi.refreshCells();
          })
          .catch((e) => {
            this.$vs.notify({
              color: 'danger',
              title: 'Delegación  no creada.',
              text: `${e.graphQLErrors.map((e) => e.message)}`,
            });
          });
      }
    },
    updateDelegation(cache, { data: { updatePlantDelegation } }) {
      if (
        updatePlantDelegation.environment != this.$data.enviromentDelegations
      ) {
        try {
          const dataSlice = cache.readQuery({
            query: DELEGATIONS,
            variables: {
              page: 1,
              first: 15,
              environment: this.$data.enviromentDelegations,
            },
          });
          let removeIndex = dataSlice.delegations.data
            .map((delegation) => delegation.id)
            .indexOf(updatePlantDelegation.id);
          dataSlice.delegations.data.splice(removeIndex, 1);
          cache.writeQuery({
            query: DELEGATIONS,
            data: dataSlice,
            variables: {
              page: 1,
              first: 15,
              environment: this.$data.enviromentDelegations,
            },
          });
          const dataPush = cache.readQuery({
            query: DELEGATIONS,
            variables: {
              page: 1,
              first: 15,
              environment: this.enviromentDelegationsCreate,
            },
          });
          dataPush.delegations.data.push(updatePlantDelegation);
          cache.writeQuery({
            query: DELEGATIONS,
            data: dataPush,
            variables: {
              page: 1,
              first: 15,
              environment: this.enviromentDelegationsCreate,
            },
          });
        } catch {
          console.log();
        }
      }
    },
    createDelegation(cache, { data: { createPlantDelegation } }) {
      //Delete deliverymen in noAsginedToPlantDeliverymen
      try {
        const data = cache.readQuery({
          query: NO_ASGINED_TO_PLANT_DELIVERYMEN,
        });
        const removeIndex = data.noAsginedToPlantDeliverymen
          .map((deliverymen) => deliverymen.id)
          .indexOf(createPlantDelegation.id);
        data.noAsginedToPlantDeliverymen.splice(removeIndex, 1);
        cache.writeQuery({
          query: NO_ASGINED_TO_PLANT_DELIVERYMEN,
          data,
        });
      } catch {
        console.log();
      }
      //Condition push type enviroment
      if (
        this.enviromentDelegationsCreate != this.$data.enviromentDelegations
      ) {
        try {
          const data = cache.readQuery({
            query: DELEGATIONS,
            variables: {
              page: 1,
              first: 15,
              environment: this.enviromentDelegationsCreate,
            },
          });
          data.delegations.data.push(createPlantDelegation);
          cache.writeQuery({
            query: DELEGATIONS,
            data,
            variables: {
              page: 1,
              first: 15,
              environment: this.enviromentDelegationsCreate,
            },
          });
        } catch {
          console.log();
        }
      } else {
        try {
          const data = cache.readQuery({
            query: DELEGATIONS,
            variables: {
              page: 1,
              first: 15,
              environment: createPlantDelegation.environment,
            },
          });
          data.delegations.data.push(createPlantDelegation);
          cache.writeQuery({
            query: DELEGATIONS,
            data,
            variables: {
              page: 1,
              first: 15,
              environment: createPlantDelegation.environment,
            },
          });
        } catch {
          console.log();
        }
      }
    },
  },
  updated() {
    // if (this.$store.state.windowWidth > 768) {
    //   this.gridApi.sizeColumnsToFit();
    // }
  },
  created() {
    this.gridOptions.context = {
      componentParent: this,
    };
  },
  mounted() {
    this.gridOptions.context = {
      componentParent: this,
    };
    this.gridApi = this.gridOptions.api;
  },
});
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';

.tabla-delegacion {
  &__prompt {
    .dialog-title {
      color: $nepal !important;
      font-weight: $font-weight-bold;
    }
  }
}
</style>
