<template>
  <div>
    <TablaDelegaciones></TablaDelegaciones>
  </div>
</template>

<script>
import TablaDelegaciones from '@/components/plantas/delegacion/TablaDelegaciones.vue';
export default {
  components: {
    TablaDelegaciones,
  },
};
</script>

<style></style>
